<template>
  <ul>
    <h1>{{ title }}</h1>
    <li v-for="d in data" :key="d.id">{{ d.id }} {{ d.topic_id }}</li>
  </ul>
</template>
<script>
export default {
  props: ["data", "title"]
};
</script>
